import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout/Layout";
import { SEO } from "../components/Seo";
import seoData from "../data/seoData.json";

const Privacy = () => (
  <Layout>
    <div className="absolute z-0 w-[100vw] max-w-[100%]">
      <div className="bgfilter" />
      <StaticImage
        src="../images/whypeople_imagen6.jpg"
        objectFit="cover"
        alt="whypeople"
        style={{
          width: "100vw",
          maxWidth: "100%",
          position: "fixed",
          zIndex: "-5",
          minHeight: "100vh",
        }}
      />
    </div>

    <div className="w-5/6 mx-auto p-6 text-sm text-primary-darkest relative z-5">
      <h1 className="text-4xl xl:text-5xl 2xl:text-6xl 4xl:text-7xl 7xl:text-8xl font-bold leading-none text-center text-primary-darkest my-12">
        POLÍTICA DE PRIVACIDAD
      </h1>
      <ol className="list-decimal [&_li]:ml-8 [&_li]:mt-2">
        <li className="font-semibold text-lg 3xl:text-xl text-primary-darker mb-2">
          RESPONSABLE DEL TRATAMIETNO
        </li>
        <ul className="[&_li]:list-disc [&_li]:ml-8 [&_li]:mt-2">
          <li>
            <span className="font-semibold">Titular</span>: WhyPeople, S.L. (en
            adelante, WHYPEOPLE)
          </li>
          <li>
            <span className="font-semibold">NIF</span>: B10630739
          </li>
          <li>
            <span className="font-semibold">Domicilio social</span>: Calle
            Julián Camarillo 7, 28037 Madrid
          </li>
          <li>
            <span className="font-semibold">Email</span>: info@whypeople.es
          </li>
        </ul>
        <li className="font-semibold text-lg 3xl:text-xl text-primary-darker pt-5 mb-2">
          INFORMACIÓN Y CONSENTIMIENTO
        </li>
        <p>
          Mediante la aceptación de la presente Política de Privacidad, el
          usuario queda informado y presta su consentimiento libre e inequívoco
          para que los datos personales que facilite a través de la página web
          sean tratados por WHYPEOPLE.
        </p>
        <li className="font-semibold text-lg 3xl:text-xl text-primary-darker pt-5 mb-2">
          OBLIGATORIEDAD DE FACILITAR LOS DATOS
        </li>
        <p>
          Todos los datos personales que le solicitamos a través de la web o
          mediante otro soporte, son obligatorios, (salvo que en el campo
          requerido se especifique lo contrario) para cumplir con las
          finalidades establecidas, no siendo posible el registro en el Sitio
          Web si no nos facilita los datos completos, sin perjuicio de que podrá
          visualizar libremente el contenido del Sitio Web.
        </p>
        <p>
          Al introducir datos en el Sitio Web, el usuario garantiza que es mayor
          de catorce (14) años y que los datos aportados son verdaderos,
          exactos, completos y actualizados, comprometiéndose a notificar a
          WHYPEOPLE cualquier tipo de modificación que se produzca en los datos
          que haya facilitado.
        </p>
        <li className="font-semibold text-lg 3xl:text-xl text-primary-darker pt-5 mb-2">
          ¿CUAL ES LA FINALIDAD DEL TRATAMIENTO DE SUS DATOS POR PARTE DE
          WHYPEOPLE?
        </li>
        <p>
          Los datos personales facilitados por el usuario serán tratados con las
          siguientes finalidades:
        </p>
        <ul className="[&_li]:list-disc [&_li]:ml-8 [&_li]:mt-2">
          <li>
            Hacer efectivo el registro del usuario para permitirle el acceso a
            los productos y servicios que le podemos ofrecer.
          </li>
          <li>
            La inclusión de sus datos referidos al curriculum en una base de
            datos de la empresa (“candidatos”), para su utilización en los
            futuros procesos de selección relacionados con las ofertas de empleo
            que la empresa publique por los distintos canales que tiene
            habilitados.
          </li>
        </ul>
        <li className="font-semibold text-lg 3xl:text-xl text-primary-darker pt-5 mb-2">
          ¿CUÁL ES LA LEGITIMACIÓN DEL TRATAMIENTO?
        </li>
        <p>
          El tratamiento de los datos del usuario está basado en el
          consentimiento expreso que se le solicita y que acepta y al que puede
          oponerse en cualquier momento.
        </p>
        <p>
          Los consentimientos obtenidos para cada una de las finalidades es
          independiente por lo que el usuario podrá revocar solo uno de ellos no
          afectando a los demás.
        </p>
        <p>
          La legitimación del tratamiento de sus datos personales también puede
          venir derivada del concepto de interés legítimo que pueda tener la
          compañía con base en la regulación establecida en las legislaciones
          que fuesen aplicables al caso.
        </p>
        <li className="font-semibold text-lg 3xl:text-xl text-primary-darker pt-5 mb-2">
          ¿CUAL ES EL PLAZO DE CONSEVACIÓN DE LOS DATOS PERSONALES DE LOS
          USUARIOS?
        </li>
        <p>
          Los datos personales se conservarán mientras se mantenga la relación
          con el usuario o hasta que ejercite su derecho de oposición y o
          cancelación de datos. Una vez finalizada la relación o ejercitado los
          mencionados derechos, los datos se bloquearán durante todo el tiempo
          exigido por la legislación aplicable y hasta que prescriban las
          eventuales responsabilidades derivadas del tratamiento.
        </p>
        <li className="font-semibold text-lg 3xl:text-xl text-primary-darker pt-5 mb-2">
          ¿CUALES SON LOS DATOS DEL USUARIO QUE PODRÁ TRATAR WHYPEOPLE?{" "}
        </li>
        <p>WHYPEOPLE tratará las siguientes categorías de datos del usuario:</p>
        <ul className="[&_li]:list-disc [&_li]:ml-8 [&_li]:mt-2">
          <li>Datos proporcionados por el propio usuario</li>
          <li>
            Datos obtenidos de otras fuentes distintas del propio usuario, como
            por ejemplo fuentes accesibles al público.
          </li>
          <li>
            Datos entregados por terceras empresas para la gestión del
            Curriculum de un candidato.
          </li>
          <li>
            Datos derivados del desarrollo de la relación: Datos proporcionado
            de forma indirecta por el cliente al derivar de la propia navegación
            de la página web, del acceso al área privada o cualquier otro de
            análoga naturaleza que permita su recepción.
          </li>
        </ul>
        <p>
          En aquellos casos en los que el usuario facilite a WHYPEOPLE datos de
          terceros manifiesta que ha informado y ha obtenido consentimiento
          previo de dicha persona para que sus datos personales sean tratados
          por parte de WHYPEOPLE. En caso de que WHYPEOPLE fuera condenada por
          un tratamiento no autorizado, se reserva el derecho a repercutir
          dichas sanciones al usuario que facilitó los datos sin consentimiento
          previo del titular.
        </p>

        <li className="font-semibold text-lg 3xl:text-xl text-primary-darker pt-5 mb-2">
          ¿QUIÉNES SON LOS DESTINATARIOS DE LOS DATOS DEL USUARIO?{" "}
        </li>
        <p>
          WHYPEOPLE no realizará ninguna cesión de datos que no tenga una base
          jurídica que lo justifique o que dicha cesión esté legitimada por
          alguna causa. Por tanto, sus datos serán objeto de tratamiento
          únicamente por WHYPEOPLE o las empresas de su grupo empresarial,
          dentro de su propio ámbito de actuación y para los distintos soportes
          (digitales y/o analógicos) en los que actúa.
        </p>
        <p>
          Sin perjuicio de lo anterior, WHYPEOPLE podrá contratar servicios de
          almacenaje en la nube o “Cloud”con entidades cuyos servidores están
          radicados fuera del Espacio Económico Europeo. No obstante, WHYPEOPLE
          se asegurará de que dichos proveedores garantizan las medidas
          adecuadas para llevar un nivel equiparable de protección en términos
          de lo dispuesto en la normativa de protección de datos.
        </p>
        <li className="font-semibold text-lg 3xl:text-xl text-primary-darker pt-5 mb-2">
          ¿CUÁLES SON SUS DERECHOS?
        </li>
        <p>
          La normativa de Protección de Datos le confiere una serie de derechos
          en relación con el tratamiento de datos que implican nuestros
          servicios y que usted podrá ejercitar, adjuntando la copia de su DNI a
          la reclamación. Los derechos son los siguientes:
        </p>
        <ul className="[&_li]:list-disc [&_li]:ml-8 [&_li]:mt-2">
          <li>
            <span className="font-semibold">Derecho de acceso</span>: Conocer
            qué tipo de datos estamos tratando y las características del
            tratamiento que se están llevando a cabo.
          </li>
          <li>
            <span className="font-semibold">Derecho de rectificación</span>:
            Puede modificar sus datos por ser inexactos o no veraces.
          </li>
          <li>
            <span className="font-semibold">Derecho de portabilidad</span>:
            Poder obtener una copia en formato interoperable de los datos que
            estén siendo tratados.
          </li>
          <li>
            <span className="font-semibold">
              Derecho a la limitación del tratamiento
            </span>
            : Cuando usted ha dado el consentimiento para el tratamiento de sus
            datos relacionados con distintas cuestiones podrá proceder a su
            limitación.
          </li>
          <li>
            <span className="font-semibold">Derecho de supresión</span>:
            Solicitar la supresión de sus datos cuando el tratamiento ya no
            resulte necesario.
          </li>
          <li>
            <span className="font-semibold">Derecho de oposición</span>:
            Solicitar el cese en el envío de comunicaciones comerciales
            anteriormente mencionado.
          </li>
          <li>
            <span className="font-semibold">Derecho a revocar</span> el
            consentimiento prestado.
          </li>
          <li>
            <span className="font-semibold">
              Derecho a interponer una reclamación
            </span>{" "}
            frente a la autoridad de control (En España la Agencia Española de
            Protección de Datos –AEPD).
          </li>
        </ul>
        <p>
          Puede ejercitar sus derechos mediante correo postal en la dirección
          postal Calle Julián Camarillo 7, 28037 Madrid, o mediante correo
          electrónico ante el Delegado de Protección de Datos de la empresa en
          la dirección info@whypeople.es indicando el derecho a ejercitar y la
          copia de su DNI.
        </p>
        <li className="font-semibold text-lg 3xl:text-xl text-primary-darker pt-5 mb-2">
          ¿CUÁLES SON LAS MEDIDAS DE SEGURIDAD QUE APLICARÁ WHYPEOPLE?
        </li>
        <p>
          WHYPEOPLE tratará los datos del usuario en todo momento de forma
          absolutamente confidencial y guardando el preceptivo deber de secreto
          respecto de los mismos, de conformidad con lo previsto en la normativa
          de aplicación, adoptando al efecto las medidas de índole técnica y
          organizativas necesarias que garanticen la seguridad de los datos y
          eviten su alteración, pérdida, tratamiento o acceso no autorizado,
          habida cuenta del estado de la tecnología, la naturaleza de los datos
          almacenados y los riesgos a que están expuestos.
        </p>
      </ol>
      <p className="font-semibold text-lg 3xl:text-xl text-primary-darker mt-8">
        DUDAS
      </p>
      <p>
        Cualquier duda, pregunta o comentario acerca de las presentes
        condiciones, podrá hacérnosla llegar dirigiendo su comunicación al
        respecto a la siguiente dirección de correo electrónico:
        info@whypeople.es
      </p>
    </div>
  </Layout>
);

export default Privacy;

export const Head = () => (
  <SEO title="Advertencia Legal" description="Información legal de WhyPeople">
    <script type="application/ld+json">{JSON.stringify(seoData)}</script>
  </SEO>
);
